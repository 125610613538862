// import jwt from 'jsonwebtoken';
// import jwtDecode from 'jwt-decode';
import {
    SET_CURRENT_USER, LOGOUT, SET_LOGIN_MODAL_OPENED, SET_REGISTER_MODAL_OPENED, SET_PROFILE_MODAL_OPENED,
    SET_RESET_PASSWORD_MODAL_OPENED, GET_USER_PAYMENT_INSTRUMENTS, GET_USER_ORDERS, SET_WAITER_TABLE_MODAL_OPENED,
    GET_TABLES
} from './types';
import setAuthorizationToken from './../../utils/setAuthorizationToken';
import axios from 'axios';
import Auth from './../../utils/Auth';
import jwt from 'jsonwebtoken';

import history from './../../utils/history';
// import { history } from './../store';
import { API_URL } from '../../constants';
import { addErrorMessages, addSuccessMessage } from './../../messages/messages.actions';
import store from './../../store';
import Utils from '../../utils/Utils';
import { getRegisteredUserDiscounts } from './../../registered_user_discounts/actions/registered_user_discounts.actions'
import { getAccumulatedOrderAmountSetting } from './../../accumulated_order_amount_setting/actions/accumulated_order_amount_setting.actions'

export function logout() {
    return dispatch => {
        Auth.deauthUser();
        setAuthorizationToken(null);
        dispatch(setCurrentUser(null));
        dispatch(setProfileModalOpened(false));
        let slug = store.getState().client.slug;

        if (slug) {
            history.push(`/${slug}`)
        }

        let trans = store.getState().lang.translations

        dispatch(addSuccessMessage(trans.login.logout_success));
        dispatch(getRegisteredUserDiscounts());

        //TODO redirect if on /profile page
        return {
            type: LOGOUT
        }
    }
}

export function login(data) {
    return dispatch => {
        axios.post(API_URL + 'login', data)
            .then(res => {
                let trans = store.getState().lang.translations

                const token = res.data.token;
                if (token) {

                    Auth.authUser(token);
                    setAuthorizationToken(token);
                    dispatch(setCurrentUser(jwt.decode(token)));
                    dispatch(getUserPaymentInstruments());
                    dispatch(addSuccessMessage(trans.login.login_success));
                    dispatch(setLoginModalOpened(false));
                    dispatch(getRegisteredUserDiscounts());
                    dispatch(getAccumulatedOrderAmountSetting());

                } else {
                    dispatch(addErrorMessages([trans.login.login_fail]));
                }
            })
            .catch(err => {
                let trans = store.getState().lang.translations

                // console.log(" ERR ", err)
                dispatch(addErrorMessages([trans.login.login_fail]));
            })
    }
}

export const register = (data) => dispatch => {
    return axios.post(API_URL + 'register', data)
    // .then(res => {
    //     console.log(res.data);
    //     // const token = res.data.token;
    //     // if (token) {
    //     //     Auth.authUser(token);
    //     //     setAuthorizationToken(token);
    //     //     dispatch(setCurrentUser(jwt.decode(token)));
    //     // } else {
    //     //     dispatch(addErrorMessages(["Problem"]));
    //     // }
    // })
    // .catch(err => {
    //     dispatch(addErrorMessages(["Problem"]));
    // })
}


export const resetPassword = (data) => dispatch => {
    return axios.post(API_URL + 'users/reset-password', data)
}


export const editProfile = (data) => dispatch => {

    let trans = store.getState().lang.translations

    axios.post(API_URL + 'users/editProfile', data)
        .then(res => {
            if (res.data.token) {
                let token = res.data.token;
                Auth.authUser(token);
                setAuthorizationToken(token);
                dispatch(setCurrentUser(jwt.decode(token)));
                dispatch(addSuccessMessage(trans.pages.profile.edit_profile_success));
            }
        })
        .catch(err => {
            if (err.response && err.response.data && err.response.data.error && err.response.data.error.errors) {
                dispatch(addErrorMessages(err.response.data.error.errors));
            } else {
                dispatch(addErrorMessages([trans.pages.profile.edit_profile_error]));
            }
        })
}

export const validateUserToken = () => dispatch => {
    // api/users/validate
    axios.post(API_URL + 'users/validate')
        .then(res => {
            if (!res.data) {
                Auth.deauthUser();
                setAuthorizationToken(null);
                // dispatch(setCurrentUser(null));

                let slug = store.getState().client.slug;

                if (slug && window.location.pathname.includes("profile")) {
                    history.push(`/${slug}`)
                }
            }
        })
}

export const getUserPaymentInstruments = () => dispatch => {
    if (!Auth.getAuthUserToken() || !Utils.hasFunctionalityModule('payments')) {
        dispatch({
            type: GET_USER_PAYMENT_INSTRUMENTS,
            payload: []
        })
        return;
    }

    axios.get(API_URL + 'users/payment-instruments')
        .then(res => {
            dispatch({
                type: GET_USER_PAYMENT_INSTRUMENTS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch(addErrorMessages(err));
        })
}

export const getUserOrders = () => dispatch => {
    if (!Auth.getAuthUserToken()) {
        dispatch({
            type: GET_USER_ORDERS,
            payload: []
        })
        return;
    }

    axios.get(API_URL + 'users/orders')
        .then(res => {
            dispatch({
                type: GET_USER_ORDERS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch(addErrorMessages(err));
        })
}

export function setCurrentUser(user) {
    return {
        type: SET_CURRENT_USER,
        user
    }
}

export function setLoginModalOpened(state) {
    return {
        type: SET_LOGIN_MODAL_OPENED,
        state
    }
}

export function setRegisterModalOpened(state) {
    return {
        type: SET_REGISTER_MODAL_OPENED,
        state
    }
}

export function setProfileModalOpened(state) {
    return {
        type: SET_PROFILE_MODAL_OPENED,
        state
    }
}


export function setResetPasswordModalOpened(state) {
    return {
        type: SET_RESET_PASSWORD_MODAL_OPENED,
        state
    }
}

export function setWaiterTableModalOpened(state) {
    return {
        type: SET_WAITER_TABLE_MODAL_OPENED,
        state
    }
}

export const getTables = () => dispatch => {

    if (!Auth.getAuthUserToken()) {
        dispatch({
            type: GET_TABLES,
            payload: []
        })
        return;
    }

    axios.get(API_URL + 'tablesMenu')
        .then(res => {
            dispatch({
                type: GET_TABLES,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch(addErrorMessages(err));
        })
}